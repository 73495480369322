.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  transform: translateX(-100%);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1); /* Slow easing */
  z-index: 1000;
  border-radius: 15px; /* Curved edges */
  overflow: hidden; /* Prevents overflow outside the overlay */
  display: flex;
  flex-direction: column; /* Allows content to stack vertically */
}

.overlay.show {
  transform: translateX(0);
}

.overlay img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
  border-radius: 10px;
}

.overlay h2 {
  margin-top: 0;
}

.overlay-content {
  flex-grow: 1; /* Allows the content to grow and fill available space */
  overflow-y: auto; /* Enables vertical scrolling */
  padding-right: 10px; /* Padding to ensure scrollbar doesn't overlap content */
}

.overlay-content div,
.overlay-content p {
  margin: 10px 0;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
