/* index.css */
h1 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-top: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.App {
  text-align: center;
}

#map {
  height: 100vh;
  width: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

nav ul li {
  display: inline;
}

nav ul li a {
  color: #000000;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

